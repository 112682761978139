"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DrawType = exports.GameState = void 0;
var GameState;
(function (GameState) {
    GameState[GameState["WAITING"] = 0] = "WAITING";
    GameState[GameState["IN_PROGRESS"] = 1] = "IN_PROGRESS";
    GameState[GameState["FINISHED"] = 2] = "FINISHED";
})(GameState = exports.GameState || (exports.GameState = {}));
var DrawType;
(function (DrawType) {
    DrawType[DrawType["DECK"] = 0] = "DECK";
    DrawType[DrawType["DISCARD"] = 1] = "DISCARD";
})(DrawType = exports.DrawType || (exports.DrawType = {}));
